import { environment } from '@gaodun.com/gtools';

/**
 * 获取domain
 * @param host host
 * @param length 将要返回的长度
 * @returns {string} domain
 */
export const getDomain = (host: string = window.location.hostname, length = 2): string => {
    let arr = host.split('.');
    if (arr.length > length) {
        return '.' + arr.slice(arr.length - length, arr.length).join('.');
    } else {
        return '.' + host;
    }
};

/**
 * 获取环境变量
 */
export const getEnv = (): string => {
    // let host: string = window.location.host;
    // // 开发环境
    // if (host.indexOf('localhost') > -1 || host.indexOf('192') > -1 || host.indexOf('127') > -1) {
    //     return 'dev-'
    // }
    // let pre: RegExpMatchArray | null = host.match(/(^.*?)-/);

    // return pre == null ? '' : pre[0]
    const env = environment.getEnv();
    return env;
};

/**
 * 获取接口请求环境前缀
 */
export const getApiEnv = (): string => {
    const apiEnv = environment.getApiEnv();
    return apiEnv;
};

/**
 * 获取接口域名前缀
 */
export const getPrefix = (): string => {
    let preFix: string = getApiEnv();
    return `https://${preFix}`;
};

/**
 * 获取带有环境前缀的字符串
 */
export const getEnvStr = (str: string) => {
    let preFix: string = getApiEnv();
    return `${preFix}${str}`;
};

/**
 * 获取网关host
 */
export const getApigatewayHost = (): string => {
    let prefix = getPrefix();
    return `${prefix}apigateway${getDomain()}`;
};

/**
 * 获取网关图片上传链接
 */
export const getFileUploadUrl = (): string => {
    let host = getApigatewayHost();
    return host + '/base/v1/upload/upload';
};

/**
 * 获取图片路径host
 * @returns imghost '{prefix}simg01.gaodunwangxiao.com/'
 */
export const getOssImageUrl = (): string => {
    let prefix = getPrefix();
    return `${prefix}simg01.gaodunwangxiao.com/`;
};

/**
 * 获取研知和大职研 图片路径host
 * @returns imghost '{prefix}simg01.gaodunwangxiao.com/'
 */
export const getOssImageYanzhiUrl = (): string => {
    let s = !getEnv() ? 'simg01' : 'img01';
    let prefix = getPrefix();
    return `${prefix}${s}.gaodunwangxiao.com/`;
};

/**
 * 获取完整图片链接
 * @param url 图片链接
 * @param project 存储项目
 */
export const picPrefixAdd = (url?: string, project = 'v', source?: string) => {
    if (!url) {
        return '';
    }
    if (/https|http/g.test(url)) {
        return url;
    }
    if(source=='yanzhi') return `${getOssImageYanzhiUrl()}${url}`;
    return `${getOssImageUrl()}${project ? project + '/' : ''}${url}`;
};

/**
 * 补充图片链接协议
 * @param url 图片链接
 */
export const picAddProtocol = (url?: string) => {
    if (!url) {
        return '';
    }
    if (/https|http/g.test(url)) {
        return url;
    }
    return `${window.location.protocol}${url}`;
};

/**
 * 获取host
 * @param host 域名
 * @returns string host
 */
export const getBaseHost = (host: string): string => {
    let prefix = getPrefix();
    return `${prefix}${host}`;
};

/**
 * 图片加载失败
 * @description 静态图片资源加载失败后，切换 simg02，simg03 依次重试
 * @param e 图片对象
 */
export const picOnError = e => {
    let _img = e.target;
    let url = _img.src;
    let urlMatch = url.match(/simg(.*)\.gaodunwangxiao.com/);
    // 图片来源为simg*.gaodunwangxiao.com
    if (urlMatch) {
        let line = Number(urlMatch[1]);
        if (line < 3) {
            _img.src = url.replace(/(simg).*(\.gaodunwangxiao.com)/, `$1${patchZero(line + 1, 2)}$2`);
        } else {
            let errorDom = document.createElement('div');
            errorDom.innerHTML = '加载失败！<br/>请检查网络态再刷新重试';
            errorDom.style.display = 'flex';
            errorDom.style.alignItems = 'center';
            errorDom.style.justifyContent = 'center';
            errorDom.style.width = '100%';
            errorDom.style.height = '200px';
            errorDom.style.maxHeight = '100%';
            errorDom.style.fontSize = '14px';
            errorDom.style.textAlign = 'center';
            errorDom.style.lineHeight = '22px';
            errorDom.style.color = '#aaa';
            errorDom.style.background = '#eee';
            _img.parentNode.replaceChild(errorDom, _img);
        }
    }
};

/**
 * 等待
 * @param time 时间毫秒
 */
export const sleep = (time) => {
    if (!time) {
        return;
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, time)
    })
}

/**
 * json数据转为formdata
 * @param json json
 */
export const json2formdata = (json: object): FormData => {
    let data = new FormData();
    for (let i in json) {
        data.append(i, json[i]);
    }
    return data;
};

/**
 * 补零
 * @param v 初始数字
 * @param l 补完0后长度 defaul:2
 */
export const patchZero = (n: number, l = 2) => {
    let value = n.toString();
    let str = '';
    for (let i = value.length; i < l; i++) {
        str += '0';
    }
    return `${str}${value}`;
};

/**
 * 数值添加每3位加“,”
 * @param n 数值
 * @returns 每3位加“,”字符串
 */
export const numberFormat = (num: number | string = 0): string => {
    num = Number(num).toString();
    let floatNum = '',
        intNum = '';

    // 判断是否有小数位，有则截取小数点后的数字
    if (num.indexOf('.') > 0) {
        intNum = num.split('.')[0];
        floatNum = num.split('.')[1];
    } else {
        intNum = num;
    }
    let result: string[] = [],
        counter = 0,
        intNumArray = intNum.split('');

    // 利用3的倍数，向数组插入','
    for (let i = intNumArray.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(intNumArray[i]);
        if (!(counter % 3) && i != 0) {
            result.unshift(',');
        }
    }
    return result.join('') + floatNum || '';
};

export function trim(str) {
    // 去空格
    return str.replace(/\s/g, '');
}

/**
 * 检查当前浏览器是否为ie9
 */
export const isIE9 = () => {
    var DEFAULT_VERSION = '9.0';
    var ua = navigator.userAgent.toLowerCase();
    var IE = ua.indexOf('msie') > -1;
    var safariVersion;
    if (IE) {
        let arr = ua.match(/msie ([\d.]+)/);
        if (!arr) return;
        safariVersion = arr[1];
        if (safariVersion === DEFAULT_VERSION) {
            return true;
        }
    }
    return false;
};
/**
 * 获取字符串里的src
 * @param value 带有src的字符串
 * @returns src
 */
export const getSrcStr = (value: string): string => {
    var reg = /src=\"([^\"]*?)\"/gi;
    var v = '';
    if (value) {
        var vArr = value.match(reg);
        v = vArr && vArr.length !== 0 ? vArr[0] : value;
        return v;
    } else {
        return value;
    }
};

/**
 * 字符串显示*加密
 */
export const strEncrypt = str => {
    return str.replace(/(\d)\d{4}(\d{4})$/, '$1****$2');
};

type DateType = Date | number | string;
/**
 * 检查时间是否为今天
 * @param date 日期
 * @param compare 比较日期，不传默认今天
 */
export const isToday = (date: DateType, compare: DateType = new Date()): boolean => {
    // 处理ios中'YYYY-MM-dd'格式new Date异常
    if (typeof date === 'string') {
        date = date.replace(/\-/g, '/');
    }
    if (typeof compare === 'string') {
        compare = compare.replace(/\-/g, '/');
    }
    if (typeof date !== 'object') {
        date = new Date(date);
    }
    if (typeof compare !== 'object') {
        compare = new Date(compare);
    }
    if (date.getFullYear() === compare.getFullYear() && date.getMonth() === compare.getMonth() && date.getDate() === compare.getDate()) {
        return true;
    }
    return false;
};

/**
 * 时间格式化
 * @param date 时间
 * @param fmt 格式 defaul:'YYYY-MM-dd hh:mm:ss'
 * @param ignore 如果当年过滤年 default: false
 * @returns '(YYYY-)MM-dd hh:mm:ss.SSS'
 */
export const dateFormat = (date: DateType, fmt = 'YYYY-MM-dd hh:mm:ss', ignore = false) => {
    // 处理ios中'YYYY-MM-dd'格式new Date异常
    if (typeof date === 'string') {
        date = date.replace(/\-/g, '/');
    }
    if (typeof date !== 'object') {
        date = new Date(date);
    }
    // 如果过滤当年，且传入日期年份和今年相同
    if (ignore && date.getFullYear() === new Date().getFullYear()) {
        fmt = fmt.replace(/^YYYY(年|\/|\-)/, '');
    }
    let ret;
    const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'M+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'h+': date.getHours().toString(), // 时
        'm+': date.getMinutes().toString(), // 分
        's+': date.getSeconds().toString(), // 秒
        'S+': date.getMilliseconds().toString(), // 毫秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : patchZero(opt[k], ret[1].length));
        }
    }
    return fmt;
};

/**
 * 时间区域格式化--重要：相同年份不显示年份
 * @param start range开始时间
 * @param end range结束时间
 * @param fmt 格式 'YYYY-MM-dd hh:mm:ss'
 * @param space 链接字符 default：'~'
 * @param ignore 当年相同时去掉开始的年
 * @returns '(YYYY-)MM-dd hh:mm:ss-(YYYY-)MM-dd hh:mm:ss'
 */
export const dataRangeFormatIgnoreSameYear = (start: DateType, end: DateType, fmt = 'YYYY-MM-dd hh:mm:ss', space = '~', ignore = false) => {
    // 处理ios中'YYYY-MM-dd'格式new Date异常
    if (typeof start === 'string') {
        start = start.replace(/\-/g, '/');
    }
    if (typeof end === 'string') {
        end = end.replace(/\-/g, '/');
    }
    if (typeof start !== 'object') {
        start = new Date(start);
    }
    if (typeof end !== 'object') {
        end = new Date(end);
    }
    let startFmt = fmt;
    let endFmt = fmt;
    // 如果开始及结束年份相同
    if (start.getFullYear() === end.getFullYear()) {
        endFmt = fmt.replace(/^YYYY(年|\/|\-)/, '');

        let now = new Date();
        // 需要忽略当年的年份，且 开始的年和今年相同
        if (ignore && now.getFullYear() === start.getFullYear()) {
            startFmt = fmt.replace(/^YYYY(年|\/|\-)/, '');
        }
    }

    return dateFormat(start, startFmt) + space + dateFormat(end, endFmt);
};

/**
 * 计算当前时间有效期
 * @param start range开始时间
 * @param end range结束时间
 * @returns  0：未开始  1：进行中  2：已结束
 */
export const diffTimesValidityState = (start: DateType, end: DateType) => {
    if (typeof start === 'string') {
        start = start.replace(/\-/g, '/');
    }
    if (typeof end === 'string') {
        end = end.replace(/\-/g, '/');
    }
    let nowTimes = new Date().getTime();
    let startTimes = new Date(start).getTime();
    let endTimes = new Date(end).getTime();
    if (nowTimes > startTimes && nowTimes < endTimes) return 1; //进行中
    else if (nowTimes >= endTimes) return 2; // 已结束
    else return 0; //未开始
};

//设置document.title
export const setDocumentTitle = (value: string) => {
    return (document.title = value);
};

/**
 * 执行html片段中的script
 * @param html html片段
 */
export const executeScript = html => {
    var reg = /<script[^>]*>([^\x00]+)$/i;
    //对整段HTML片段按<\/script>拆分
    var htmlBlock = html.split('</script>');
    for (var i in htmlBlock) {
        var blocks; //匹配正则表达式的内容数组，blocks[1]就是真正的一段脚本内容，因为前面reg定义我们用了括号进行了捕获分组
        if ((blocks = htmlBlock[i].match(reg))) {
            //清除可能存在的注释标记，对于注释结尾-->可以忽略处理，eval一样能正常工作
            var code = blocks[1].replace(/<!--/, '');
            try {
                eval(code); //执行脚本
            } catch (e) {}
        }
    }
};

/**
 * 生成随机数
 * @minNum到maxNum的随机数
 */
export const randomNum = function (minNum: number, maxNum: number) {
    switch (arguments.length) {
        case 1:
            return Math.random() * minNum + 1;
        case 2:
            return Math.random() * (maxNum - minNum + 1) + minNum;
        default:
            return 0;
    }
};
/**
 * 处理url特殊参数
 * @scene参数在url中object对象处理
 */
export const getUrlParmas = (search: any) => {
    let str = search;
    let str2 = {};
    for (let i in str) {
        let newKey = i;
        if (i.indexOf('scene') > -1) {
            newKey = i.replace('{"scene":"', '');
        }
        if (/\}/.test(str[i])) {
            str[newKey] = str[i].replace('"}', '');
        }

        str2[newKey] = str[i];
    }

    return str2;
};

/**
 * 打开新窗口，window.open 在ios中不可用
 */
export const openNewWindow = (url: string) => {
    if (!url) return;
    let anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.target = '_blank';
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

// 是否是mPaaS容器
export const isMPaaS = () => {
    if (/Alipay|mPaaS/i.test(navigator.userAgent)) {
        return true;
    }
    return false;
};