import { get, post, put, del } from '../utils/fetch';

const FormUrlencoded = {
    'Content-Type': 'application/x-www-form-urlencoded',
};
const FormDate = {
    'Content-Type': 'application/form-data',
};
// 通过projectId&collegeId获取公众号信息
export const querySourceInfo = p => get('/luffy/internal/api/v1/wx/source', p, { visitor: true });

// 通过x_field获取公众号信息
export const querySourceInfoByFlag = p => get('/luffy/internal/api/v1/wx/flag', p, { visitor: true });

// 通过shopid获取公众号信息
export const querySourceInfoByShopId = p => get('/luffy/internal/api/v1/wx/shop', p, { visitor: true });

// 获取国家区号
export const queryCountrycodeList = p => get('/notice/api/v1/countrycode', p, { visitor: true });

// 发送验证码
export const sendmessage = p => post('/sso/v1/member/sendmessage', p, { visitor: true, headers: FormUrlencoded });

// 发送验证码
export const sendMessageWithCaptcha = p => post('/passport/api/v1/captcha/send', p, { visitor: true });

// 检查是否为ocrm代理商账号
export const checkOcrmUserPhone = p => get('/nami/mobile/v1/agent/check/agent-or-ocrm-user', p, { visitor: true });

// 验证码登录
export const loginByCode = p => post('/api/v1/ucenter/loginsms', p, { visitor: true, headers: FormUrlencoded });

// 海外手机号验证码登录
export const LoginByOtherType = p => post('api/v1/type_login', p, { visitor: true, headers: FormDate });

// 登录并绑定公众号
export const loginAndBind = p => post('/api/v1/ucenter/loginAndBind', p, { visitor: true, headers: FormDate });

// 绑定手机号
export const bindPhone = p => post('/sso/api/v2/bindPhone', p, { headers: FormUrlencoded });

// 更新用户信息
export const updataUserInfo = p => post('/sso/editbaseinfo', p, { headers: FormUrlencoded });

// 解除用户账号与微信公众号的绑定关系
export const unbindingwx = p => post('/sso/unbindingwx', p, { headers: FormUrlencoded });

// 更新用户微信信息
export const updateUserWxInfo = p => post('/passport/api/v3/updateUserInfo', p);

// 获取微信公众号签名
// export const getWxSignature = (p) => post('/wx-service/api/v1/jsapi/sign', p, { visitor: true })

// 获取微信公众号签名
export const getWxSignature = p => post('https://wechat.gaodunwangxiao.com/api/v1/wechat/jsapi/sign', p, { visitor: true });

// 检查token状态
export const checkLogin = () => post('/api/v1/token/checklogin', {});

// 获取活动列表
export const getActivityList = data => post('/luffy/internal/api/v1/activity/list/v1', data, { visitor: true });

// 获取新H5列表
export const getH5ActivityList = id => get(`/luffy/internal/api/v1/activity/getH5ActivityList?groupId=${id}`, {}, { visitor: true });

// 获取商品详情
// export const getProductDetailById = (id) => get(`/luffy/internal/v1/api/marketing-product/goods-detail/${id}`, {}, { visitor: true })

// 获取活动详情
export const getActivityDetailById = id => get(`/luffy/internal/api/v1/activity/detail/${id}`, {}, { visitor: true });

// 获取活动商品
// export const getProductDetailByXfield = (x_field) => get(`/luffy/internal/v1/api/marketing-product/goods-detail-info/${x_field}`, {}, { visitor: true })

// 获取活动详情
export const getActivityDetailByXfield = x_field => get(`/luffy/internal/api/v1/activity/detail-info/${x_field}`, {}, { visitor: true });

// 获取活动关联投放计划组
export const getActivityChannelGroupById = id => get(`/luffy/internal/api/v1/activity/channel-delivery-group/${id}`, {}, { visitor: true });

// 获取助教列表
export const getTeacherList = (data?: any) => get('/luffy/api/v1/teaching_assistants', data);

// 获取助教信息by appid和orderid
export const queryTeacherInfoByAppIdAndOid = (data?: any) => get('/luffy/api/v1/teaching_assistants/byAppIdAndOrderNo', data);

// 获取助教信息通过商品信息
export const queryTeachinfoByProductInfo = (p: any) => post('/luffy/internal/api/v1/allocation/get-sales-push-clue', p);

// 获取公众号信息by appid和orderid
export const queryGZHInfoByAppIdAndOid = (data?: any) => get('/luffy/internal/api/v1/we_chat/public_account/byAppIdAndOrderNo', data);

// 获取学员任务详情
export const queryScrmStudentTasks = data => get('/luffy/api/v1/student_service/task/state', data);

//根据活动详情获取订单列表
export const queryOrderByActId = (data?: any) => get('/luffy/internal/api/v1/order/activity/order/info', data);

// 获取学习列表
export const queryClassList = () => get('/luffy/internal/api/v1/course/list', {});

// 根据课程ids获G2课程详情列表
export const queryG2ClassDetails = p => post('/luffy/internal/api/v1/course/getG2LiveInfos', p);

// 获取指定idscrm订单班级信息
export const queryScrmClassDetailById = p => get('/luffy/internal/api/v1/course/info', p);

// 创建订单
export const createOrder = p => post('/luffy/internal/api/v1/order/create', p);

// 仅创建订单
export const createOrderOnly = p => post('/luffy/internal/api/v1/order/only/create', p);

// 获取订单列表
export const queryOrderList = p => get('/luffy/internal/api/v1/order/list', p);

// 通过orderid获取订单详情
export const queryOrderDetail = p => get('/luffy/internal/api/v1/order/info', p);

// 通过ordertoken获取订单信息
export const queryOrderDetailByOrderToken = p => get('/luffy/internal/api/v1/order/info_by_ordertoken', p);

// 取消订单
export const cancelOrder = p => post('/luffy/internal/api/v1/order/cancel', p);

// 订单再支付
export const orderArousePayAgain = p => post('/luffy/internal/api/v1/order/pay', p);

// 通过ordertoken获取助教信息
export const getTeacherByOrdertoken = p => get('/luffy/internal/api/v1/order/assistant', p);

// 通过ordertoken 获取是否订单完成显示内容
export const getOrderOverShow = p => get('/luffy/internal/api/v1/order/activity/info', p);

// 获取小程序scheme url
export const getAppletScheme = p => post('/luffy/internal/api/v1/wechat/applet/scheme', p, { visitor: true });

// 获取问卷第一个问题
export const getFirstQuestion = p => get('/luffy/internal/api/v1/survey/first_question', p);

// 获取问卷下一个问题
export const getNextQuestion = p => get('/luffy/internal/api/v1/survey/next_question', p);

// 保存问卷答案
export const saveQuestionAnswer = p => get('/luffy/internal/api/v1/survey/save_question_answer', p);

// 通过ordertoken获取投放计划
export const getPlanByOrderToken = orderToken => get(`/luffy/internal/api/v1/order/channel_delivery/${orderToken}`, {});

// 通过orderToken 获取投放计划中的公众号二维码url
export const getOrderGZHQrUrl = p => get('/luffy/internal/api/v1/order/activity', p);

// 通过ordertoken 获取dd订单号
export const getDdNoInfo = (p) => get('/luffy/internal/api/v1/order/distribution/activity/info', p)


// 通过orderToken 获取投放计划中的公众号信息
export const getOrderGZHInfo = p => get('/luffy/internal/api/v1/shop/ordertoken', p);

// 获取adderass list
export const queryAddressList = p => get('/prm/v1/delivery_address/list', p);

// 新增地址
export const addAddress = p => post('/prm/v1/delivery_address', p);

// 编辑地址
export const editAddress = p => put('/prm/v1/delivery_address', p);

// 通过地址id删除地址
export const delAddressById = id => del(`/prm/v1/delivery_address/${id}`, {});

// 通过地址id获取地址信息
export const queryAddressInfoById = id => get(`/prm//v1/delivery_address/${id}`, {});

// 获取省列表
export const qureyProvinceList = () => get('/prm/dict/v1/dict', { dictCode: 'PROVINCE' }, { visitor: true });

// 获取省以下信息
export const queryRegionSubInfo = p => get('/prm/dict/v1/queryChildren', p, { visitor: true });

// 获取拼团列表
export const quereGroupListByProductId = p => get(`/luffy/internal/api/v1/groupon/${p.productId}/list/${p.count}`, {}, { visitor: true });

/** 获取团购详情 type 为0传团购ID，为1传订单编号 */
export const quereGroupInfoByIdAndType = (id, type) => get(`/luffy/internal/api/v1/groupon/${id}/${type}`, {}, { visitor: true });

// 参团
export const joinGroupByGroupId = (q, p) => post(`/luffy/internal/api/v1/groupon/join?${q}`, p);

// 获取优惠券列表
export const queryCouponList = p => get(`/luffy/internal/api/v1/coupon/list`, p);

// 获取问卷信息
export const queryQustionsInfo = p => get(`/luffy/internal/v1/api/marketing-activity/survey/detail`, p, { visitor: true });

//提交问卷信息
export const answerQustions = p => post(`/luffy/internal/api/v1/survey/submit`, p);

// 根据问卷查询助教信息
export const getTeacherInfoBySurvey = p => post(`/luffy/internal/api/v1/allocation/get-survey-sales`, p);

// 获取对应问卷答案
export const queryAnswersByXfieldOrActId = p => get(`/luffy/internal/v1/api/marketing-activity/survey/answer`, p);
// 未登录判断获取对应问卷答案
export const queryAnswersByNoLoginAnswer = p => get(`/luffy/internal/v1/api/marketing-activity/survey/noLoginAnswer`, p, { visitor: true });

// 验证码获取服务推送
export const servicePushWhenSendSms = p => post(`/luffy/internal/api/v1/clue/send-sms`, p, { visitor: true });

// 获取活码信息
export const queryAutoQrcodeInfo = p => get(`/luffy/internal/api/v1/volatileGroup/getqr`, p);

// 我的活动列表
export const queryAppointmentList = p => post(`/luffy/internal/api/v1/appointment/list`, p);

// 检查用户是否参与活动
export const appointmentCheck = p => get(`/luffy/internal/api/v1/appointment/check`, p);

// 获取提交表单后的助教id
export const getRedirect = p => get(`/luffy/internal/api/v1/survey/afterSubmit/getRedirectConfig`, p);

// 获取抽奖页面信息
export const lotteryInfo = p => post(`/luffy/internal/api/v1/mkt/scren/lottery/lotteryInfo`, p);

// 参与抽奖
export const lotteryParticipate = p => post(`/luffy/internal/api/v1/mkt/scren/lottery/participate`, p);

// 检验是否参加过手机抽奖活动
export const lotteryPageCheckUserParticipate = p => get(`/luffy/internal/api/v1/mkt/mobile/lottery/checkUserParticipate`, p);

// 手机抽奖活动详情
export const lotteryPageInfo = p => get(`/luffy/internal/api/v1/mkt/mobile/lottery/info`, p);

// 手机抽奖奖品详情
export const lotteryPageDetails = p => get(`/luffy/internal/api/v1/mkt/mobile/lottery/prize-info`, p);

// 手机抽奖逻辑
export const lotteryPageRaffle = p => post(`/luffy/internal/api/v1/mkt/mobile/lottery/raffle`, p);

// 当前登录者中奖历史
export const lotteryPageRecord = (p?: any) => get(`/luffy/internal/api/v1/mkt/mobile/lottery/winLotteryHistory`, p);

// 获取手机中奖结果
export const lotteryPageWinner = p => get(`/luffy/internal/api/v1/mkt/mobile/lottery/getWinner`, p);

// 获取秒杀报名人数
export const getDoneOrderInfo = q => get(`/luffy/internal/api/v1/activity/get/done/order-info/${q}`, {}, { visitor: true });

// 拼团生成海报
export const grouponPoster = p => get(`/luffy/internal/api/v1/groupon/poster`, p);

// 根据活动Id获取拼团二维码
export const grouponQrcode = p => get(`/luffy/internal/api/v1/groupon/qrcode`, p);

// 获取mcrm额外参数
export const grouponExt = p => get(`/luffy/internal/api/v1/groupon/ext`, p);

// 获取企微开关状态
export const switchWxStatus = (p?) => get('/luffy/api/v1/wecom/switch/status', p);

// 获取当前销售的微信好友
export const getFriendRelateStaff = p => get('/wecom-manage/external/v1.0/friend-relate/staff', p);

// 获取小风车商品
export const getWindMillProductList = p => get('/luffy/internal/api/v1/activity/getG2ActivityList', p, { visitor: true });

// 通过项目获取兜底账号信息
export const getBottomTeachingAssistant = p => get('/luffy/internal/api/v1/teaching_assistants/getBottomTeachingAssistant', p);

// 获取活动详情
export const getDeliveryProductGroup = p => get(`/luffy/internal/api/v1/activity/getDeliveryProductGroup`, p, { visitor: true });
//根据订单标识获取订单详情
export const getByOrderToken = p => get('/luffy/internal/api/v1/order/info_by_ordertoken', p);

// 获取我的助教-优惠券
export const getTeacherByCoupon = p => get('/luffy/internal/api/v1/coupon/assistant', p);

// 获取助教-单页面
export const getClueOwnerByProjectId = p => get('/luffy/internal/api/v1/allocation/getClueOwnerByProjectId', p);

// 高顿会计 小程序跳转的scheme接口
export const getAccountantSchema = p => get('/app-camp/web/v1/wechat/getSchema', p);

// 推送G2点击线索
export const pushG2ClickClue = p => get(`/luffy/internal/api/v1/activity/pushG2ClickClue?${p}`, {});

//查询模板详情
export const queryTemplateDetail = p => get(`/luffy/internal/api/template/config/detail`, p, { visitor: true });

// 获取协议列表
export const getAgreementList = (p?: any) => post('/luffy/v1/api/agreements/list', p, { visitor: true });